import React, { useRef, useState, useEffect } from 'react';
import { Worker, Viewer, SpecialZoomLevel, ViewMode, ScrollMode } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import { pageNavigationPlugin, NextIcon, PreviousIcon} from '@react-pdf-viewer/page-navigation';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import DisableRightClickWrapper from './DisableRightClickWrapper';
import { GlobalWorkerOptions } from 'pdfjs-dist/build/pdf';

const PDFViewPPT = ({ pdf }) => {
    const [fullScreen, setFullScreen] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js`;

    const toolbarPluginInstance = toolbarPlugin();
    const pageNavigationPluginInstance = pageNavigationPlugin();
    const { jumpToNextPage, jumpToPreviousPage, CurrentPageLabel } = pageNavigationPluginInstance;

    const containerRef = useRef(null);

    const handleFullscreen = () => {
        if (!document.fullscreenElement) {
            setFullScreen(true);
            if (containerRef.current) {
                if (containerRef.current.requestFullscreen) {
                    containerRef.current.requestFullscreen();
                } else if (containerRef.current.mozRequestFullScreen) { /* Firefox */
                    containerRef.current.mozRequestFullScreen();
                } else if (containerRef.current.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                    containerRef.current.webkitRequestFullscreen();
                } else if (containerRef.current.msRequestFullscreen) { /* IE/Edge */
                    containerRef.current.msRequestFullscreen();
                }
            }
        } else {
            setFullScreen(false);
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { /* Firefox */
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE/Edge */
                document.msExitFullscreen();
            }
        }
    };

    const handleDocumentLoad = (e) => {
        setTotalPages(e.doc.numPages)
    };

    const handlePageChange = (page) => {
        setCurrentPage(page.currentPage);
    };

    useEffect(() => {
        const handleWheel = (e) => {
            e.preventDefault();
        };
        const container = containerRef.current;
        if (container) {
            container.addEventListener('wheel', handleWheel);
        }
        return () => {
            if (container) {
                container.removeEventListener('wheel', handleWheel);
            }
        };
    }, []);

    return (
        <DisableRightClickWrapper>
            <div className="relative border border-gray-300 rounded-lg p-1 w-full md:w-3/4 mx-auto" ref={containerRef}>
                <button
                    onClick={handleFullscreen}
                    className="absolute p-2 bg-green-500 text-white rounded z-10 hidden md:block left-4 top-4"
                >
                    {!fullScreen ? "Enter Fullscreen" : "Exit Fullscreen"}
                </button>
                <div className={`mb-4 w-full ${fullScreen ? "h-[90vh]" : "h-[75vh]"}`}>
                    {pdf ? (
                        <Worker workerUrl={`//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js`}>
                            <Viewer
                                fileUrl={pdf}
                                plugins={[toolbarPluginInstance, pageNavigationPluginInstance]}
                                defaultScale={SpecialZoomLevel.PageFit}
                                viewMode={ViewMode.SinglePage}
                                onDocumentLoad={handleDocumentLoad}
                                scrollMode={ScrollMode.Horizontal}
                                onPageChange={handlePageChange}
                                initialPage={0}
                            />
                        </Worker>
                    ) : (
                        <p>There is no content here yet. Once the material is available it will show up here.</p>
                    )}
                </div>
                <div className="flex justify-center mt-4">
                    <button onClick={() => jumpToPreviousPage()} disabled={currentPage === 0} className="px-4 py-2 bg-blue-500 text-white rounded mx-2">
                        Previous
                    </button>
                    <CurrentPageLabel />
                    <button onClick={() => jumpToNextPage()} disabled={currentPage === totalPages - 1} className="px-4 py-2 bg-blue-500 text-white rounded mx-2">
                        Next
                    </button>
                </div>
            </div>
        </DisableRightClickWrapper>
    );
};

export default PDFViewPPT;
