import React from "react";
import SideNav from "./SideNav";
import LargeCard from "./LargeCard";
import { useAllUsers, useUnapprovedTestimonials, deleteTestimonial, updateTestimonial } from "../../util/db";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import LoadingIcon from "../LoadingIcon";



const columns = ["Name", "Email", "Phone Number", "Company", "Subscription(s)"]

  

function CRMSection(props) {

  const {
    data: testimonials,
    status: testimonialsStatus,
    error: testimonialsError
  } = useUnapprovedTestimonials()

  const {data: people,
    status: peopleStatus,
    error: peopleError,
  } = useAllUsers()

  return (
    <>
      <div className="container">
      <div className="flex w-full">

        <div className="flex-grow px-4">
          <>
          {(testimonials && testimonialsStatus === "success" && testimonials.length >0)&&
          <LargeCard titleDescriptions  ={[{title: "Testimonials Awaiting Approval", description: "Approve testimonials before they go live."}]}>
        {(testimonials && testimonialsStatus === "success" && testimonials.length>0)?testimonials.map((testimonial, index) => (
            <>
            <div className="flex flex-row items-center">
              <div className="flex flex-col">
                <button onClick={() => updateTestimonial(testimonial.id, {approved: true})} className="bg-green-700 text-white h-min m-2 p-2 rounded-lg">Approve Testimonial</button>
                <button onClick={() => deleteTestimonial(testimonial.id)} className="bg-red-700 text-white h-min m-2 p-2 rounded-lg">Delete Testimonial</button>
              </div>
            {(testimonial.type === "text")&&
            <div
              className="flex-1 border rounded-md border-green-700 relative min-w-[300px] max-w-full"
              key={index}
            >
              <div className="absolute top-0 right-0 text-8xl mt-1 mr-2 text-green-700 opacity-75 font-serif">
                “
              </div>
              <div className="px-4 pt-14 pb-6 sm:px-6 sm:pb-6 relative">
                <blockquote>
                <p className="leading-7 mb-2 font-bold">{testimonial.title}</p>
                  <p className="leading-7 mb-5">{testimonial.description}</p>
                  <footer className="flex items-center space-x-4">
                    <UserCircleIcon className="h-16" />
                    <div>
                      <span className="font-semibold text-green-600">
                        {testimonial.display_name}
                      </span>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
            }
            </div>

            {(testimonial.type === "video")&&<div>This would be a video</div>}
            </>
          )):
          <div className="flex w-full justify-center items-center">
          {(testimonialsStatus !== "success")&&<LoadingIcon className="h-12"/>}
          </div>
          }
        </LargeCard>}
        <div className="flex w-full pt-2">
        <LargeCard titleDescriptions = {[{title: "Customers", description: "Here you can view and manage our customers."}]}>
        {(peopleStatus === "success" && people && people.length >0)&& (
        <div className="p-1 divide-solid divide-y">
        <div className="flex justify-between py-2 px-2 font-bold">
        {columns.map((each, k) => 
        <div key = {each} className={`w-1/6 ${k===0?"text-start":"text-center"}`}>{each}</div>
        )}
        </div>

        {people.map((person, k) => (
            <div key={person.id} className={`flex justify-between py-2 px-2 text-sm text-gray-500 ${k %2===0?"bg-green-50":""}`}>
            <div className="w-1/6 text-start">{person.name?person.name:"Not Provided"}</div>
            <div className="w-1/6 text-center">{person.email?person.email: "Not Provided"}</div>
            <div className="w-1/6 text-center">{person.phone?person.phone: "Not Provided"}</div>
            <div className="w-1/6 text-center">{person.company?person.company: "Not Provided"}</div>
            <div className="w-1/6 text-center">
            {person.stripePriceIds?.includes(process.env.REACT_APP_STRIPE_PRICE_LIFE)?"Lifetime Membership ":""}
            {person.stripePriceIds?.includes(process.env.REACT_APP_STRIPE_PRICE_TP)||person.stripePriceIds?.includes(process.env.REACT_APP_STRIPE_PRICE_TPL)?"Trainings Package ":""}
            {person.stripePriceIds?.includes(process.env.REACT_APP_STRIPE_PRICE_PROC)?"Processes Package ":""}
            {(person.stripePriceIds?.includes(process.env.REACT_APP_STRIPE_PRICE_SBM) ||person.stripePriceIds?.includes(process.env.REACT_APP_STRIPE_PRICE_SBY))?"Stairs and Buckets ":""}
            {person.stripePriceIds || person.legacyPlans?"": "No Subscription"}
            {person.legacyPlans?.includes("life")?"Lifetime Membership Legacy ":""}
            {person.legacyPlans?.includes("tp")?"Trainings Package Legacy ":""}
            {person.legacyPlans?.includes("proc")?"Processes Package Legacy ":""}
            {(person.legacyPlans?.includes("sbm") ||person.legacyPlans?.includes('sby'))?"Stairs and Buckets Legacy ":""}
            </div>
            </div>
        ))}
        </div>
        )}
            </LargeCard>
        </div>

        </>
        </div>
    </div>

      </div>
      <SideNav current = "admin"></SideNav>

    </>

  );
}

export default CRMSection;
