import React from "react";
import CalendarComponent from "../../components/Custom/CalendarComponent";
import { useCodesByOwner, useTasksByOwner } from "../../util/db";
import ValidatePaymentWrapper from "../../components/Custom/ValidatePaymentWrapper";


function CalendarSection(props) {

    const {
        data: videoCodes,
        error: videoCodesError,
        status: videoCodesStatus
    } = useCodesByOwner(props.uid)

    const {
      data: tasks,
      error: tasksError,
      status: tasksStatus
    } = useTasksByOwner(props.uid)


  return (
    <>
      <ValidatePaymentWrapper requiredPlans = {["sbm","sby", "proc", "tp", "tpl", "life"]}>
      <div className="container">
      <div>
        {(videoCodes && videoCodesStatus==="success" && tasksStatus === "success")&&(
        <CalendarComponent uid = {props.uid} tasks= {tasks} codes = {videoCodes}/>
        )}
        </div>
      </div>
      </ValidatePaymentWrapper>

    </>

  );
}

export default CalendarSection;


