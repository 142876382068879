import React from "react";
import "./../styles/global.css";
import ApplicationTop from "../components/Custom/ApplicationTop";
import CustomIndexPage from "./Custom/customindex";
import AboutPage from "./about";
import FaqPage from "./faq";
import ContactPage from "./contact";
import PricingPage from "./pricing";
import BlogPostsPage from "./Custom/blog";
import DashboardPage from "./dashboard";
import CRMPage from "./Custom/crm";
import TrainingPage from "./Custom/training";
import TeamsPage from "./Custom/teams"
import HelpPage from "./Custom/help";
import AuthPage from "./auth";
import PackageInfoPage from "./Custom/packageinfo";
import SettingsPage from "./settings";
import LegalPage from "./legal";
import { Switch, Route, Router } from "./../util/router";
import PurchasePage from "./purchase";
import FirebaseActionPage from "./firebase-action";
import NotFoundPage from "./404";
import MemberViewPage from "./Custom/memberview";
import Footer from "./../components/Footer";
import CustomFooter from "../components/Custom/CustomFooter"
import "./../util/analytics";
import Chat from "./../components/Chat";
import { AuthProvider } from "./../util/auth";
import { QueryClientProvider } from "./../util/db";
import ClientViewPage from "./Custom/clientview";
import InfoPage from "./Custom/info";
import CalendarPage from "./Custom/calendar";
import TestimonialsPage from "./Custom/testimonials";
import LandingPage from "./Custom/landing";

function App(props) {
  return (
    <QueryClientProvider>
      <AuthProvider>
        <Chat />
        <Router>
          <>
            
            <ApplicationTop bgColor="bg-white" type = "Dashboard"/>

            <Switch>
              <Route exact path="/all" component={CustomIndexPage} />

              <Route exact path="/" component={LandingPage} />

              <Route exact path="/about" component={AboutPage} />

              {/* <Route exact path="/testimonials" component={TestimonialsPage} /> */}

              <Route exact path="/view/member/:index" component={MemberViewPage}/>

              {/* <Route path="/blog/:index" component={BlogPostsPage}/> */}

              <Route exact path="/packages/:section" component={PackageInfoPage} />

              <Route exact path="/faq" component={FaqPage} />

              <Route exact path="/contact" component={ContactPage} />

              <Route exact path="/pricing/:section" component={PricingPage} />

              <Route exact path="/admin" component={CRMPage} />

              <Route exact path="/dashboard/:section" component={DashboardPage} />

              <Route exact path="/tools/:section" component={TrainingPage} />

              <Route exact path="/view/:code" component={ClientViewPage} />

              <Route path="/teams/:id" component={TeamsPage} />

              <Route exact path="/settings/:section" component={SettingsPage} />

              <Route exact path = "/help/:section" component = {HelpPage} />

              <Route exact path="/auth/:type" component={AuthPage} />

              <Route exact path="/legal/:section" component={LegalPage} />

              <Route exact path="/purchase/:plan" component={PurchasePage} />

              <Route exact path="/info/:type" component={InfoPage} />
              
              <Route exact path="/calendar" component={CalendarPage} />



              <Route
                exact
                path="/firebase-action"
                component={FirebaseActionPage}
              />

              <Route component={NotFoundPage} />
            </Switch>
            <CustomFooter/>

            {/* <Footer
              size="md"
              bgColor="bg-white"
              bgImage=""
              bgImageOpacity={1}
              textColor=""
              sticky={true}
            /> */}
          </>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
