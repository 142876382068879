import React from "react";
import Meta from "../../components/Meta";
import { useRouter } from "../../util/router";
import { Link } from "../../util/router";
import {MegaphoneIcon, LockOpenIcon, SunIcon, UserIcon, Square3Stack3DIcon,ClockIcon, ArrowTrendingUpIcon, AcademicCapIcon, CurrencyDollarIcon, HandThumbUpIcon, BanknotesIcon, PencilSquareIcon } from "@heroicons/react/24/solid";
import TestimonialsSection from "../../components/TestimonialsSection";
import InfoSection from "../../components/Custom/InfoSection";
import FrontHeader from "../../components/Custom/FrontHeader";
import PerksList from "../../components/Custom/PerksList";
import { useAuth } from "../../util/auth";

var procImage = 'https://firebasestorage.googleapis.com/v0/b/insurace-agency-trainin-9mw02g.appspot.com/o/coverPhotos%2Fproc.png?alt=media&token=06369a59-baf7-4dae-b19e-0eb6c7b5d671'
var tpImage = 'https://firebasestorage.googleapis.com/v0/b/insurace-agency-trainin-9mw02g.appspot.com/o/coverPhotos%2Ftp.png?alt=media&token=ab6b57e5-803d-40d6-88ac-27dd66b92e90'
var sbImage = 'https://firebasestorage.googleapis.com/v0/b/insurace-agency-trainin-9mw02g.appspot.com/o/coverPhotos%2Fsb.png?alt=media&token=8ab5c3b9-bd26-41f8-ad8e-948de1588b5f'
var lifeImage = 'https://firebasestorage.googleapis.com/v0/b/insurace-agency-trainin-9mw02g.appspot.com/o/coverPhotos%2Flife.png?alt=media&token=bc27bf89-d10a-4980-baad-fcd218c595eb'
var million_dollar_manual_Image = "https://firebasestorage.googleapis.com/v0/b/insurace-agency-trainin-9mw02g.appspot.com/o/coverPhotos%2Fmillion_dollar_manual.png?alt=media&token=1d2fadbe-c267-4f1f-abaa-94c1fa38faac"
var paychecks_never_lie_Image = "https://firebasestorage.googleapis.com/v0/b/insurace-agency-trainin-9mw02g.appspot.com/o/coverPhotos%2Fpaychecks_never_lie.png?alt=media&token=333f5fdc-222b-406e-8ffc-b7e4f2fb34e5"
var bigger_better_paychecks_Image = "https://firebasestorage.googleapis.com/v0/b/insurace-agency-trainin-9mw02g.appspot.com/o/coverPhotos%2Fbigger_better_paychecks.png?alt=media&token=9171e66d-5bd9-46b4-9741-5ef74d17fbdc"
var staff_handbook_Image = "https://firebasestorage.googleapis.com/v0/b/insurace-agency-trainin-9mw02g.appspot.com/o/coverPhotos%2Fstaff_handbook.png?alt=media&token=d04a865e-7a3a-45ee-8b57-a8cdc4cc6626"
function PackageInfoPage(props) {
  const auth = useAuth()
  const router = useRouter();
  const sbperks = [
    {
      title: 'Simplicity and Efficiency',
      description: 'Stairs and buckets is an easy-to-understand approach, making complex financial concepts accessible.',
      icon: <ClockIcon className="h-8" />,
    },
    {
      title: 'Overcomes Challenges',
      description: 'Stairs and buckets overcomes the challenge of selling non-demand life insurance products.',
      icon: <ArrowTrendingUpIcon className="h-8" />,
    },
    {
      title: 'Become an Advisor',
      description: 'Stairs and buckets transforms agents into trusted advisors by enabling deeper client interactions.',
      icon: <AcademicCapIcon className="h-8" />,
    },
    {
        title: 'Saving Time Making Money',
        description: 'Train your team to do their jobs, saving you time and making you money.',
        icon: <CurrencyDollarIcon className="h-8" />,
      },
      {
        title: 'Risk Free Trial Period',
        description: '100% Money Back Guarantee. Money back, no-questions-asked refund if you cancel within 3 days.',
        icon: <HandThumbUpIcon className="h-8" />,
      },
      {
        title: 'No Brainer Pricing',
        description: 'Discounted pricing: only $59/mo or $599/yr!',
        icon: <BanknotesIcon className="h-8" />,
      },
    // Add more perks as needed
  ];
  const tpperks = [
    {
    title: 'Enhanced Client Relationships and Retention',
    description: `"The insights from 'Paychecks Never Lie' and 'Bigger Better Paychecks' have profoundly impacted our approach to client interactions, resulting in increased satisfaction and loyalty." - Matt, New Mexico`,
    icon: <UserIcon className="h-8" />,
    },
    {
    title: 'Turned our Agency Around',
    description: `"Adopting the strategies from the Million Dollar Manual was a game-changer for our struggling agency. We've Seen a complete turnaround in our performance, with improved client retention and a more cohesive team environment." -Mary, Maine `,
    icon: <UserIcon className="h-8" />,
    },
    {
    title: 'Revenue and Team Morale Soared!',
    description: `"Since integrating the Million Dollar Training Bundle, our agency has seen a remarkable growth. Our revenue has increased significantly, and the team's morale has never been higher." -Henry, Nevada`,
    icon: <UserIcon className="h-8" />,
    },
    {
    title: 'Skyrocketed Productivity and Sales',
    description: `"The structure and techniques provided in the training bundle have significantly boosted our team's productivity and sales effectiveness. It feels like we've unlocked a higher level of performance and client service." -Jill, Oklahoma`,
    icon: <UserIcon className="h-8" />,
    },
    {
    title: 'Invest in Your Agencies Future',
    description: 'Unlock the full potential of your agency without breaking the bank. Our comprehensive training bundle is available at an unbeatable price of just $3,999/yr.',
    icon: <UserIcon className="h-8" />,
    },
    {
    title: 'Expert-Crafted Non-Compete Clause',
    description: 'Crafted at the expense of over $3,000 with legal expertise, this clause is a crucial tool for safeguarding your business.',
    icon: <UserIcon className="h-8" />,
    },
    
    ]
    const proctestimonials = [
        {
        name: 'Greg',
        body: `The Medicare Supplement Process opened my eyes to how simple the selling of medicare supplements can be. Since implementing it, my team's confidence in discussing these options has soared, leading to a 40% increase in our Medicare supplement sales.`,
        role: "Arizona",
        },
        {
        name: 'Sandra',
        body: `The Flexible Care Benefit process was a game-changer for us. We're now offering better long-term care solutions, and I've seen a significant rise in Universal Life sales, especially with clients over 50.`,
        role: "Texas",
        },
        {
        name: 'Angela',
        body: `The 'Finding the Money' process has been instrumental in educating our clients about smart money management. It's rewarding to see their financial growth, and our whole life policy sales have doubled!`,
        role: "Florida",
        },
        {
        name: 'Keith',
        body: `Learning to balance protection and market participation has been crucial for my clients. This process has enhanced our approach to financial advising, leading to more informed and satisfied clients.`,
        role: "Ohia",
        },
        {
        name: 'Diane',
        body: 'Understanding Money and Banking Bluepring has changed how we advise clients on savings and investments. Our approach now mirrors successful banking strategies, resulting in higher premium life cases.',
        role: "California",
        },
        {
        name: 'Mark',
        body: `The Liability Wall process has been a revelation. We're now better at outlining the importance of liability protection, which has notably increased our sales in liability and life insurance policies.`,
        role: "Georgia",
        },
        
    ]
    const procperks = [
        {
        title: "Comprehensive Training Materials",
        description: 'Detailed guides and tools for each of the six sales processes.',
        icon: <PencilSquareIcon className="h-8" />,
        },
        {
        title: "Ongoing Support and Updates",
        description: 'Regular updates and support to keep you at the forefront of industry trends and best practices.',
        icon: <ClockIcon className="h-8" />,
        },
        {
        title: "Bang for your Buck",
        description: 'Annual Pricing of $1,999/yr.',
        icon: <CurrencyDollarIcon className="h-8" />,
        },
        {
        title: "Six Processes",
        description: "Includes 'Medicare Supplement', 'Large Premium Life', 'Finding the Money', 'Protect and Participate', 'Millionare Money', and 'Liability Wall' sales processes.",
        icon: <Square3Stack3DIcon className="h-8" />,
        },
    ]
    const lifeperks = [
        {
            title: "Lifetime Access to All Trainings",
            description: "Get lifetime access to every training program on InsuranceAgencyTraining.org. This includes all existing courses and materials, plus instant access to all future content we develop.",
            icon: <ClockIcon className="h-8"/>
        },
        {
            title: "Yearly Exclusive Two-Day Mountain Retreat in Wyoming",
            description: "Immerse yourself in a two-day, in-person training and networking event held in the majestic mountains south of Jackson Hole, Wyoming. Including live seminars, trainings, Q&A sessions, a cowboy cookout, and more.",
            icon: <SunIcon className="h-8"/>
        },
        {
            title: "Unlock the Experience",
            description: "The Million Dollar Club isn't just about training; it's about experiencing a transformative journey in your professional life. All for a one-time fee of $15,000.",
            icon: <LockOpenIcon className="h-8"/>
        },
]
    const sbtestimonials = [
        {
        name: 'Jen',
        body: `This video is amazing. I showed it to my first client yesterday.
        When we were done I explained they had a term policy [which] was going to go up and they said, "I want a bucket." When I asked them how much they could afford, they said, "$1,200 a month."
        We ended up writing a $1200 a month 15 pay policy. The biggest one I've ever done. ON my first try. I'm a believer. Thank you for this tool.`,
        },
        {
        name: 'Art',
        body: `"Stairs and Buckets" is the best thing I have done for my team's production. They have sold over $200k in premiem in the first half of this year. My team is really good at using this now and I can't imagine not having it, this really has changed the way we approach customers about life insurance. We highly endorse and recommend this to everyone in the business!`,
        },
        {
        name: 'Will',
        body: `I have struggled selling much besides term insurance with my clients until I came across this training. Now I sell larger whole life and universal life consistently. This has become an effective tool in my conversations with customers. Last your wwe traveled and sold over $120,000 in premium and I have only been an agent for 4 years now.`,
        },
        
    ]
  return (
    <>
      <Meta title="Packages" />
      <div className="container text-center">
        {(router.query.section === "sb")&&(<>
        <InfoSection photo = {sbImage} href={auth.user? `/pricing/plans`: `/auth/signup?next=/pricing/plans`} subtitle = "Stairs & Buckets 101"header = "Stairs & Buckets" title="Try the video, webinar training, tools and guide risk free!" cta="Buy Now" perks = {["Save time and make $100,000+ using stairs and buckets.", "A repeatable, simple 4-minute video tool for consistently training your team."]}/>
        <FrontHeader text = "Reasons to use Stairs and Buckets"/>
        <div className="mb-6">
        <PerksList perks={sbperks}/>
        <div className="my-4">
        <TestimonialsSection testimonials={sbtestimonials}/>
        </div>
        <Link to={auth.user? `/pricing/plans`: `/auth/signup?next=/pricing/plans`}>
        <button className="bg-green-700 m-4 w-3/4 text-5xl text-white rounded-lg p-6">Buy Now</button>
        </Link>
        </div>

        </>)}
        {(router.query.section === "tp")&&(<>
            <InfoSection photo={tpImage} href={auth.user? `/pricing/plans`: `/auth/signup?next=/pricing/plans`} subtitle = "Training Package & Manuals"  header = "Agency Training" title="Agency Growth Made Simple: Our Training Bundle" cta="Buy Now" perks = {["Equip your team with solid skills and effective playbooks for every aspect of insurance agency management and sales.", "Straightforward, actionable training resources to enhance your agency's performance and profitability from the ground up.", "Manuals, Scripts, Non-Compete + Templates"]}/>
            <FrontHeader text = "Reasons to Use The Trainings Package"/>
            <div className="mb-6">
            <PerksList perks={tpperks}/>
            <InfoSection photo={million_dollar_manual_Image} href={auth.user? `/pricing/plans`: `/auth/signup?next=/pricing/plans`} cta="Get This and More" header = "Included with Every Subscription" title="The Million Dollar Manual" perks = {["A comprehensive guide emphasizing understanding your 'why', communication skills, long-term business retention strategies, and techniques in auto, home, and life insurance, including objection handling and wealth accumulation via life insurance.", "It also covers Medicare Supplement Plans, team management, recruitment, and financial optimization of your agency, supplemented with a curated list of additional resources."]}/>
            <InfoSection photo={staff_handbook_Image} href={auth.user? `/pricing/plans`: `/auth/signup?next=/pricing/plans`} cta="Get This and More" title="The Comprehensive Staff Handbook" perks = {["This handbook includes descriptions for how to manage a million dollar agency: policies, procedures, financial guidelines, and other activities required to sell at a high level.", "Its unique value lies in the included legal no-competition agreement, protecting the agency from employees potentially stealing clients."]}/>
            <InfoSection photo={paychecks_never_lie_Image} href={auth.user? `/pricing/plans`: `/auth/signup?next=/pricing/plans`} cta="Get This and More" title="Paychecks Never Lie" perks = {["The goal of this book is", "1. -to help you learn strategies for your business to run with better results.", "2. -to establish a philosophy and pattern of learning and coaching.", "3. -to help you establish practice sessions for you and your team to create profit.", "4. -to help you take action and get started. Without motion you aren't going anywhere!"]}/>
            <InfoSection photo={bigger_better_paychecks_Image} href={auth.user? `/pricing/plans`: `/auth/signup?next=/pricing/plans`} cta="Get This and More" title="Bigger Better Paychecks" perks = {["This book focuses on developing the habits and mindset of top performers for better business, life, and personal growth.", "It covers defining success, the psychology of winners, deep practice, the benefits of a four-day workweek, effective communication through storytelling, and maintaining integrity for substantial income."]}/>
            <Link to ={auth.user? `/pricing/plans`: `/auth/signup?next=/pricing/plans`}>
            <button className="bg-green-700 m-4 w-3/4 text-5xl text-white rounded-lg p-6"> Buy Now</button>
            </Link>
            </div>
        </>)}
        {(router.query.section === "proc")&&(<>
            <InfoSection photo = {procImage} href={auth.user? `/pricing/plans`: `/auth/signup?next=/pricing/plans`} subtitle = "Sales Processes & Webinars"  header = "Sales Processes" title="Mastering Financial Security: Six Sales Processes for Life's Risks" cta="Buy Now" perks = {["Dive into a comprehensive program designed to guide insurance agents through crucial conversations about long-term care, Medicare, financial understanding and more.", "Our six specialized processes are tailored to address the key financial risks everyone faces at some point in their lives."]}/>
            <FrontHeader text = "A Done-For-You Approach"/>
            <div className="mb-8">
            <TestimonialsSection testimonials = {proctestimonials}/>
            </div>
            <FrontHeader text = "Why is this plan for you?"/>
            <PerksList perks={procperks}/>
            <Link to ={auth.user? `/pricing/plans`: `/auth/signup?next=/pricing/plans`}>
            <button className="bg-green-700 m-4 w-3/4 text-5xl text-white rounded-lg p-6"> Buy Now</button>
            </Link>
            <div className="mb-12"></div>

        </>)}
        {(router.query.section === "life")&&(<>
         <InfoSection photo = {lifeImage} href = {auth.user? `/pricing/plans`: `/auth/signup?next=/pricing/plans`} subtitle = "Includes All Current and Future Content"  header = "The Million-Dollar Membership" title="Lifetime Mastery and Exclusive Experiences: Your Gateway to Insurance Excellence" cta="Buy Now" perks = {["Join the Million Dollar Club for an unparalleled journey in insurance expertise.", "A one-time investment grants you lifetime access to all our current and future trainings, and exclusive networking events with top agents."]}/>
         <FrontHeader text = "Unprecedented Value of the Million Dollar Lifetime Membership"/>
            <PerksList perks={lifeperks}/>
            <Link to = {auth.user? `/pricing/plans`: `/auth/signup?next=/pricing/plans`}>
            <button className="bg-green-700 m-4 w-3/4 text-5xl text-white rounded-lg p-6"> Buy Now</button>
            </Link>
        </>)}
        </div>
    </>
  );
}

export default PackageInfoPage;
