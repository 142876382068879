import { apiRequest, apiRequestExternal } from "./util";

export async function handleZapier(method, data) {
  try {
      const response = await apiRequest('zapier', "POST", {
        action: method,
        data: data
      });
      return(response)
  } catch (error) {
      console.error('Error:', error);
      throw error
  }
}

export async function handleZapierExternal(method, data) {
  try {
      const response = await apiRequestExternal('https://insuranceagencytraining.org/api/zapier', "POST", {
        action: method,
        data: data
      });
      return(response)
  } catch (error) {
      console.error('Error:', error);
      throw error
  }
}