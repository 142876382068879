import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { EnvelopeIcon, EnvelopeOpenIcon } from '@heroicons/react/24/outline';
ChartJS.register(ArcElement, Tooltip, Legend);

const PieChartStatsCard = ({ opened, unopened }) => {
  const chartData = {
    labels: ["Opened", "Unopened"],
    datasets: [
      {
        data: [opened, unopened],
        backgroundColor: ['#15803d', '#e53935'],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    rotation: -90, // Start angle for the half doughnut
    circumference: 180, // Sweep angle for the half doughnut
    cutout: '70%', // Adjust the size of the hole in the center
    plugins: {
      tooltip: {
        enabled: false, // Disable tooltip
      },
      legend: {
        display: false, // Hide the legend to reduce whitespace
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    maintainAspectRatio: false, // Allows custom width and height
  };

  return (
    <div className="flex flex-col md:flex-row justify-around rounded-lg border bg-green-50 relative">
      <div className="relative h-32 md:w-64 p-4 justify-center w-full">
        <Doughnut data={chartData} options={options} />
        <div className="absolute inset-0 flex flex-col items-center justify-center mt-12">
          <span className="text-2xl font-bold">{opened + unopened}</span>
          <span className="text-sm text-gray-400">Codes Sent</span> {/* Added margin-top */}
        </div>
      </div>
      <div className='flex flex-col'>
      <div className='flex flex-row items-center border bg-gray-100 rounded-lg space-x-6 p-2 my-1 justify-between text-center'>
            <EnvelopeOpenIcon className='h-10 text-green-600 '/>
            <div>
            <span className="font-semibold text-lg">{opened} Links</span>
            <p className='text-gray-400 text-xs'>Links Accessed</p>
        </div>
        <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-md font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                {Math.round(opened*100 / (opened+unopened))}%
        </span>
      </div>
      <div className='flex flex-row items-center border bg-gray-100 rounded-lg space-x-6 p-2 my-1 justify-between text-center'>
            <EnvelopeIcon className='h-10 text-green-600 '/>
            <div>
            <span className="font-semibold text-lg">{unopened} Links</span>
            <p className='text-gray-400 text-xs'>Links Not Accessed</p>
        </div>
        <span className="inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-md font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                {Math.round(unopened*100 / (opened+unopened))}%
            </span>
      </div>
      </div>


    </div>
  );
};

export default PieChartStatsCard;
