import React from 'react';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';

const DownloadButton = () => {

  return (
    <div className="relative inline-block text-left">
      {/* Button to toggle the dropdown */}
      <button  className="flex flex-row bg-white text-green-500 py-2 px-4 rounded-md">
      <ArrowDownTrayIcon className='h-6 mr-0.5'/>
        Download Report
      </button>

    </div>
  );
};

export default DownloadButton;
