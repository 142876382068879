import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Link } from 'react-router-dom'; // Assuming you're using react-router for navigation
import { EllipsisHorizontalIcon, CakeIcon, PhotoIcon, CheckCircleIcon } from '@heroicons/react/24/solid';
import { deleteTeam, updateTeam, handleTeamPhotoPost } from '../../util/db';
import { useAuth } from '../../util/auth';

const TeamCard = ({ team }) => {
  const auth = useAuth()
  const [menuOpen, setMenuOpen] = useState(false);
  const [editPopop, setEditPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [leavePopup, setLeavePopup] = useState(false);
  const [teamName, setTeamName] = useState(team.name);
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [success, setSuccess] = useState(false)
  const [file, setFile] = useState()



  const defaultAvatar = 'https://via.placeholder.com/150?text=Avatar';

  const handleClickOutside = (event) => {
    if (!event.target.closest('.dots')) {
      setMenuOpen(false);
    }
  };
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleEdit = () => {
    setEditPopup(!editPopop);
    setMenuOpen(!menuOpen)
  };
  const toggleDelete = () => {
    setDeletePopup(!deletePopup);
    setMenuOpen(!menuOpen)
  };
  const toggleLeave = () => {
    setLeavePopup(!leavePopup);
    setMenuOpen(!menuOpen)
  };

  const closeEdit = () => {
    setEditPopup(!editPopop)
    setFile()
    setSuccess(false)
  }

  async function updateTeamSettings (id) {
    setButtonDisabled(true)
    await updateTeam(id, {name: teamName})
    if (file) {
      await handleTeamPhotoPost(file[0], id)
    }
    setFile()
    setSuccess(true)
    setButtonDisabled(false)
  }
  async function asyncDelete (id) {
    await deleteTeam(id)
    setDeletePopup(false)
  }

  async function asyncLeave (id) {
    await updateTeam(id, {members: team.members.filter(each => each !== auth.user.uid)})
    setDeletePopup(false)
  }

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

    const onDrop = useCallback((acceptedFiles) => {
      // Handle the files
      setFile(acceptedFiles)
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      accept: 'image/*',
      maxSize: 10485760
    });
  return (
    <>
    <div className={`relative rounded-lg hover:bg-green-50 min-w-[280px] ${team.active?"border border-green-700":""}`}>
      <Link to={`/teams/${team.id}`} className="flex flex-col items-start p-4 text-gray-800 hover:text-gray-600">
        <img src={team.profilePhoto?team.profilePhoto:defaultAvatar} alt={team.name} className="h-16 w-16 rounded-md object-cover mr-4" />
        <div>
          <div className="text-lg font-semibold mt-2">{team.name}</div>
          <div className="text-sm text-gray-500">{team.members.length} Members</div>
        </div>
      </Link>
      <div className="absolute top-0 right-0 p-2">
        <button onClick={toggleMenu} className="dots text-gray-600 hover:text-gray-800 focus:outline-none">
        <EllipsisHorizontalIcon className='h-10'/>
        </button>
        {menuOpen && team.owner === auth.user.uid? (
          <>
          <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20">
            <button onClick={toggleEdit} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">Edit</button>
            <button onClick={toggleDelete} className="block px-4 py-2 text-sm text-red-700 hover:bg-gray-100 w-full text-left">Delete</button>
          </div>
          </>
        ):(menuOpen&&
          <>
          <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20">
            <button onClick={toggleLeave} className="block px-4 py-2 text-sm text-red-700 hover:bg-gray-100 w-full text-left">Leave Team</button>
          </div>
          </>
        )}
      </div>
    </div>
    {editPopop&&(
        <div className="z-20 fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center">
          <div className="bg-white p-8 m-4 rounded-lg shadow-lg w-full sm:w-1/2 max-w-4xl relative">
            {(success)?
            <>
            <div className="flex flex-col text-center space-y-4">
            <CakeIcon className='h-12 text-green-600'/>
            <h2 className='text-5xl font-semibold'>Settings Updated Successfully!</h2>
            <p className='text-lg font-semithin'>{`If you would like to edit the members in this team, please click on the team and manage members directly from there.`} </p>
            <p className='text-lg text-gray-400'>Feel free to come and update these settings any time!</p>

            <button onClick={()=> closeEdit()} className=" absolute top-0 right-4 text-3xl text-gray-400 rounded-md">
            &times;
          </button>
          </div>
            </>
            :<>
            <h2 className=" text-center text-5xl font-bold pb-6">Edit This Team</h2>
            <button onClick={()=> closeEdit()} className=" absolute top-0 right-4 text-3xl text-gray-400 rounded-md">
              &times;
            </button>
            <div className='h-0 my-4 border-t-4 border-dotted border-gray-200'></div>
            <p className='text-gray-400 text-left m-2'>Team Name</p>
            <div className='mb-4'>
                <label htmlFor="email" className="sr-only">
                    Team Name
                </label>
                <input
                    type="text"
                    name="name"
                    id="name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Sender's Full Name"
                    value={teamName}
                    onChange={(e) => setTeamName(e.target.value)}
                />
            </div>
            <div className="col-span-full mb-4">
      <label htmlFor="cover-photo" className="text-gray-400 text-left m-2">
        Cover photo
      </label>
      <div
        {...getRootProps({
          className:
            'mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10'
        })}
      >
        <input {...getInputProps()} />
        <div className="text-center">
          {file?<CheckCircleIcon className='h-12 w-12 text-green-600 mx-auto'/>:<PhotoIcon aria-hidden="true" className="mx-auto h-12 w-12 text-gray-300" />}
          <div className="mt-4 flex text-sm leading-6 text-gray-600">
            <label
              htmlFor="file-upload"
              className="relative cursor-pointer rounded-md bg-white font-semibold text-green-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-green-600 focus-within:ring-offset-2 hover:text-green-500"
            >
              <span>Upload a file</span>
            </label>
            <p className="pl-1">or drag and drop</p>
          </div>
          {file?<p className='text-green-600 font-semibold'>Photo Selected</p>:<p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>}
        </div>
      </div>
    </div>
            <button disabled={(teamName === team.name && !file) || buttonDisabled} onClick={()=>updateTeamSettings(team.id)} className="flex flex-row bg-green-600 text-gray-200 py-2 px-4 w-full justify-center rounded-md focus:outline-none focus:border-green-700">Update Settings</button>
            </>}

          </div>
        </div>
    )}

    {deletePopup&&(
        <div className="z-20 fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg shadow-lg md:w-1/2 m-4 w-full max-w-4xl relative">
            <>
            <div className="flex flex-col text-center space-y-4">
            <h2 className='text-3xl font-semibold'>Confirmation</h2>
            <p className='text-lg font-semithin'>{`Are you sure you would like to delete this team? This action cannot be undone.`} </p>
            <div className='flex flex-row justify-around'>
            <button onClick={ () =>asyncDelete(team.id)} className=" items-center rounded-full bg-red-50 w-1/4 py-2 text-md font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
              Delete Team
              </button>
              <button onClick={() => toggleDelete()} className=" items-center rounded-full bg-gray-50 w-1/4 py-2 text-md font-medium text-gray-700 ring-1 ring-inset ring-gray-600/10">
              Cancel
              </button>
            </div>
            <button onClick={()=> toggleDelete()} className=" absolute top-0 right-4 text-3xl text-gray-400 rounded-md">
            &times;
          </button>
          </div>
            </>
          </div>
        </div>
    )}

    {leavePopup&&(
        <div className="z-20 fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg shadow-lg md:w-1/2 m-4 w-full max-w-4xl relative">
            <>
            <div className="flex flex-col text-center space-y-4">
            <h2 className='text-3xl font-semibold'>Confirmation</h2>
            <p className='text-lg font-semithin'>{`Are you sure you would like to leave this team? This action cannot be undone.`} </p>
            <div className='flex flex-row justify-around'>
            <button onClick={ () =>asyncLeave(team.id)} className=" items-center rounded-full bg-red-50 w-1/4 py-2 text-md font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
              Leave Team
              </button>
              <button onClick={() => toggleLeave()} className=" items-center rounded-full bg-gray-50 w-1/4 py-2 text-md font-medium text-gray-700 ring-1 ring-inset ring-gray-600/10">
              Cancel
              </button>
            </div>
            <button onClick={()=> toggleLeave()} className=" absolute top-0 right-4 text-3xl text-gray-400 rounded-md">
            &times;
          </button>
          </div>
            </>
          </div>
        </div>
    )}
    </>
  );
};

export default TeamCard;
