import React from "react"
import { useState } from "react"
import {PlusCircleIcon, CakeIcon} from "@heroicons/react/24/solid";
import { useAuth } from "../../util/auth";
import { createTeam } from "../../util/db";
export default function CreateTeamCard() {
    const auth = useAuth()
    const [isOpen, setisOpen] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [name, setName] = useState("")
    const [successfullPost, setSuccessfullPost] = useState(false)

    function closePopup() {
        setisOpen(false)
        setSuccessfullPost(false)
        setButtonDisabled(false)
      }
    async function postNewTeam() {
        setButtonDisabled(true)
        await createTeam({
            name: name,
            owner: auth.user.uid,
            members: [auth.user.uid],
            active: true,
        })
        setSuccessfullPost(true)
        setButtonDisabled(false)
    }

    return (
        <>
        {(isOpen)&&(
                <div className="z-20 fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center">
                  {/* Popup content */}
                  <div className="bg-white p-8 rounded-lg shadow-lg lg:w-1/2 w-3/4 max-w-4xl relative">
                    {successfullPost===true?(
                    <div className="flex flex-col text-center space-y-4">
                    <CakeIcon className='h-12 text-green-600'/>
                    <h2 className='text-5xl font-semibold'>Team Created Successfully!</h2>
                    <p className='text-lg font-semithin'>Congratulations. Remember, if you ever need to edit this team, you can do that from within the team itself.</p>
                    <button onClick={()=> closePopup()} className=" absolute top-0 right-4 text-3xl text-gray-400 rounded-md">
                    &times;
                  </button>
                  </div>
                  ):(
                    <>
                    <h2 className=" text-center text-5xl font-bold pb-6">Create A New Team</h2>
                    <p className='text-gray-600 text-center'>We just need a little bit more information before we can create this team for you!</p>
                    <button onClick={()=> closePopup()} className=" absolute top-0 right-4 text-3xl text-gray-400 rounded-md">
                      &times;
                    </button>

                    <div className='h-0 my-4 border-t-4 border-dotted border-gray-200'></div>
                    <p className='text-gray-400 text-left m-2'>Team Name</p>
                    <div className='mb-4'>
                        <label htmlFor="email" className="sr-only">
                            Team Name
                        </label>
                        <input
                            type="text"
                            name="name"
                            id="name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder='"The Best Team Name Ever"'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <button onClick={()=>postNewTeam()} disabled={buttonDisabled} className="flex flex-row bg-green-600 text-gray-200 py-2 px-4 w-full justify-center rounded-md focus:outline-none focus:border-green-700">Create Team</button>
                    </>)}
        
                  </div>
                </div>
        )}
        <button
            type="button"
            onClick={() => {setisOpen(true)}}
            className="relative flex flex-col items-center block min-w-[280px] rounded-lg border-2 border-dashed border-green-700 p-12 text-center hover:border-green-500 focus:outline-none text-green-700 hover:text-green-500 hover:bg-green-50"
        >
            <PlusCircleIcon className="h-12 justify-center "/>
            <span className="mt-2 block text-sm font-semibold text-gray-900">Create a New Team</span>
        </button>
        </>

    )
  }