import { PlusCircleIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import {handleFileUpload} from '../../util/db';
import LoadingIcon from '../LoadingIcon';

const EntityCard = ({ imageUrl, name, role, company, edit, uid }) => {
  // Default avatar image
  const defaultAvatar = 'https://via.placeholder.com/150?text=Avatar';
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false)

async function postData() {
  setButtonDisabled(true)
  await handleFileUpload(file, uid)
  setFile(null)
  setFileName('')
  setButtonDisabled(false)
}

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      setFile(file);
    } else {
      setFileName('');
      setFile(null);
    }
  };

  return (
    <div className="flex flex-col md:flex-row items-center space-x-4 p-4">
      {/* Profile Image with fallback to default avatar */}
      <img
        src={file ? URL.createObjectURL(file) : imageUrl || defaultAvatar}
        alt="Profile"
        className="h-24 w-24 rounded-full object-cover"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // Prevent looping
          currentTarget.src = defaultAvatar; // Fallback to default avatar if the image fails to load
        }}
      />
      {/* Name and Role */}
      <div>
        <div className="text-xl font-bold">{name}</div>
        <div className="text-md text-gray-600">{role}</div>
        <div className="text-sm text-gray-400">{company}</div>
      </div>
      {edit && (
        <div className="flex flex-row items-center">
          
          {!file&&<label
            htmlFor="file-upload"
            className='flex flex-row items-center text-green-600 rounded-lg border-green-600 border p-2 hover:cursor-pointer'
          >
            <span className='flex flex-row items-center'>
              <PlusCircleIcon className='h-6 mr-1' />
              Upload Photo
            </span>
            <input
              id="file-upload"
              name="file-upload"
              type="file"
              accept="image/*"
              className="sr-only"
              onChange={handleFileChange}
            />
          </label>}

          {file&&<button disabled={buttonDisabled} onClick={() => postData()} className='flex flex-row items-center text-green-600 rounded-lg border-green-600 border p-2 hover:cursor-pointer'>
            Save Changes
            {buttonDisabled&&<LoadingIcon className="h-6"/>}
          </button>}


          {fileName && <span className="text-green-600 ml-2 ">{fileName}</span>}
        </div>
      )}
    </div>
  );
};

export default EntityCard;
