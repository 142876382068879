import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import StatsSection from "./../components/StatsSection";
import ClientsSection from "../components/ClientsSection";
import CtaSection from "./../components/CtaSection";

function AboutPage(props) {
  return (
    <>
      <Meta title="About" description="Learn about our company and team" />
      <HeroSection
        title={
          <>
            About <span className="font-light">Us</span>
          </>
        }
        subtitle="We are a team of insurance and financial professionals with a lifetime of success at the highest levels in our
        companies. Our trainings have been showcased around the globe by Million Dollar Round Table (MDRT) Agents. These principles have also been practiced in offices, coffee shops and around kitchen tables, helping people understand why they would want life insurance as a key part of their portfolios.
        We believe that life insurance is an essential tool in preparing for the future and preserving financial stability.
        We are dedicated to helping insurance agents empower their teams, educate their customers and improve their sales."
        strapline="Our Story"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
        leftImage="https://images.unsplash.com/photo-1542744173-8e7e53415bb0?crop=entropy&fit=crop&h=800&w=1280"
        rightImage="https://images.unsplash.com/photo-1554232456-8727aae0cfa4?crop=entropy&fit=crop&h=800&q=80&w=600"
      />
      <StatsSection
        title="Trusted by Tens of Thousands"
        subtitle="Agents from all over the world are growing with our platform and community. Join them today!"
        strapline="Inspiring Results"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <ClientsSection
        title="Our Partners"
        subtitle=""
        strapline=""
        size="md"
        bgColor="bg-green-800"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-white"
      />
      <CtaSection
        title={
          <>
            Create an account <span className="text-green-600">today</span>!
          </>
        }
        subtitle="Inspiring results from day one without the pain. Get your own custom dashboard and start building amazing services."
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
    </>
  );
}

export default AboutPage;
