import React from 'react';
import { CheckIcon } from '@heroicons/react/24/solid';

const PerksList = ({ perks }) => {
  return (
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
          {perks.map((perk, index) => (
            <div key={index} className="flex flex-col items-center bg-white p-6 rounded-lg border border-gray-200">
              <div className="text-green-600 mb-4">
                {perk.icon||<CheckIcon className='h-8'/>} {/* Default icon if none provided */}
              </div>
              <h3 className="text-3xl font-semibold mb-2 text-green-800">{perk.title}</h3>
              <p className="text-gray-700 text-center text-xl">{perk.description}</p>
            </div>
          ))}
        </div>
      </div>
  );
};

export default PerksList;
