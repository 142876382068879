import React from 'react';
import { CheckIcon } from '@heroicons/react/24/solid'; // Import the check icon for the perks list
import image from "../../assets/planlogo.png"
import { Link } from '../../util/router';

const PlanCard = ({ id, price, perks, title, period, handleClick, suppliedIds, owned, subtitle }) => {

  const sendDataToParent = () => {
    handleClick(id);
  };

  return (
    <div className="w-full m-2 rounded-lg overflow-hidden bg-white border border-green-700">
      {/* Logo */}
      <div className="flex bg-green-50 justify-center p-4">
        <img src={image} alt="Subscription Logo"/>
      </div>

      <div className="text-center bg-green-50 text-md text-gray-500 p-2">
        {title}
      </div>
      <div className='flex flex-row items-end justify-center bg-green-50'>
        <p className="text-center bg-green-50 text-4xl text-green-700 font-bold p-2">
        {`$${price}`}
        </p>
        <p className="text-center bg-green-50 text-md text-gray-500 p-2">{period?period:"per month"}</p>
      </div>
      <p className='text-center bg-green-50 text-md text-gray-500 border-b border-dashed border-green-700'>{subtitle}</p>

      {/* Subscribe Button */}
      <div className="flex justify-center p-4">
        <button disabled={owned} onClick={sendDataToParent} className={` ${suppliedIds.includes(id) || owned?"bg-gray-100 text-green-700": "text-white bg-green-700 hover:bg-green-600"} w-full  py-2 px-6 rounded-md `}>
        {suppliedIds.includes(id)?"Selected":owned?"Already Owned!":"Select"}
        </button>
      </div>

      {/* Perks List */}
      <ul className="list-none p-4 space-y-2">
        {perks.map((perk, index) => (
          <li key={index} className="flex items-center">
            <CheckIcon className="h-5 w-5 text-green-500 mr-2"/>
            {perk}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PlanCard;