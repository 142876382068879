import React, { useState } from 'react';
import { updateUser } from '../../util/db';

const EditInformationCard = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [information, setInformation] = useState(props.informationArray);

  async function handleEditClick() {
    if (isEditing && props.collection === "users") {
      setDisabled(true)
      await updateUser(props.id, {
        name: information[0].value,
        company: information[1].value,
        phone: information[3].value,
        role: information[4].value
      })
    }
    setIsEditing(!isEditing);
    setDisabled(false)
  };

  const handleInputChange = (index, event) => {
    const newInformation = [...information];
    newInformation[index].value = event.target.value;
    setInformation(newInformation);
  };

  return (
    <div className="flex flex-col md:flex-row justify-between rounded-lg p-4 w-full border border-solid border-gray-200">
      <div className="flex flex-col items-left space-y-4">
        <h3 className="text-xl font-semibold">{props.title}</h3>
        {information.map((each, index) => (
          <div key={index}>
            <p className='text-gray-400 text-sm'>{each.name}</p>
            {isEditing&&each.name!=="Email" ? (
              <input
                type="text"
                value={each.value}
                onChange={(event) => handleInputChange(index, event)}
                className="border border-gray-300 rounded p-1"
              />
            ) : (
              <p>{each.value}</p>
            )}
          </div>
        ))}
      </div>
      <button
      disabled={disabled}
        onClick={handleEditClick}
        className="flex flex-row px-4 h-min py-2 bg-gray-white text-green-600 border border-solid border-green-600 rounded-md"
      >
        {isEditing ? 'Save Changes' : 'Edit Information'}
      </button>
    </div>
  );
};

export default EditInformationCard;
