import React, { useState, useEffect } from "react";
import { Link, useRouter } from "../../util/router";
import { useAuth } from "../../util/auth";
import SideNav from "./SideNav";
import LargeCard from "./LargeCard";
import EditInformationCard from "./EditInformationCard";
import EntityCard from "./EntityCard";
import { useUser, useNotifications, createSupportForm } from "../../util/db";
import NotificationsSection from "./NotificationsSection";
import SettingsBilling from "../SettingsBilling";
import SettingsPassword from "../SettingsPassword";
import FormAlert from "../FormAlert";
import MarkdownRenderer from "./MarkDownRenderer";
import { apiRequest } from "../../util/util";
import { getPlanName } from "../../util/prices";
import {CakeIcon} from "@heroicons/react/24/solid";


const navigation = [
    { name: 'Account Details', href: 'account', current: false },
    { name: 'Notifications', href: 'notifications', current: false },
    { name: 'Subscriptions', href: 'subscriptions', current: false },
    { name: 'Terms and Conditions', href: 'tac', current: false },
    { name: 'Privacy Policy', href: 'privacy', current: false },
    // { name: 'About', href: 'about', current: false },

  ]
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  
  // async function postZap() {
  //   const resp =  await  handleZapier("postOrder", 
  //     {"full_name": "Test Name",
  //       "order_number": "123457",
  //       "unique_id": "aslkdjflldf",
  //       "order_date": "8/19/24",
  //       "email": "test@test.com",
  //       "ship_address_line_1": "2512 Willet Dr.",
  //       "ship_address_line_2": "Box 243",
  //       "ship_address_city": "Laramie",
  //       "ship_address_state": "WY",
  //       "ship_address_zip": "82070",
  //       "item_name": "Million Dollar Club Test",
  //       "quantity": "2"
  //     })
  //   console.log(resp)
  //  }

function CustomSettingsSection(props) {

  const priv = require("../../assets/privacy_policy.md")
  const tos = require("../../assets/terms_of_service.md")

  const auth = useAuth();
  const router = useRouter()
  const [billing, setBilling] = useState(false)
  const [priv_pol_md, setPriv_pol_md] = useState("")
  const [tos_md, setTos_md] = useState("")
  const [formAlert, setFormAlert] = useState(null);
  const [body, setBody] = useState("") 

  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [successfullPost, setSuccessfullPost] = useState(false)
  const [formOpen, setFormOpen] = useState(false)



  // State to control whether we show a re-authentication flow
  // Required by some security sensitive actions, such as changing password.
  const [reauthState, setReauthState] = useState({
    show: false,
  });

  const [showPasswordModal, setShowPasswordModal] = useState(false)

  const {
    data: user,
    error: userError,
    status: userStatus
  } = useUser(auth.user.uid)

  const {
    data: notifications,
    error: notificationsError,
    status: notificationsStatus
  } = useNotifications(auth.user.uid)

 async function submitForm() {
  setButtonDisabled(true)
  try {
    const data = {
          message: `<p>New support thread from the email ${auth.user.email},</p></br> <p>They sent the message:</p></br> <p>"${body}"</p>`,
          email: "Ben.olivas.iat@gmail.com",
          method: "Email",
          subject: "New Support Form"
      };

      const response = await apiRequest('send-message', 'POST', data);
      console.log('Response after sending code:', response);
  } catch (error) {
    console.error("Error creating code: ", error)
  }

  createSupportForm({
    owner: auth.user.uid,
    email: auth.user.email,
    body: body
  })
  setButtonDisabled(false)
  setSuccessfullPost(true)
 }

  const handleStatus = ({ type, message, callback }) => {
    if (type === "requires-recent-login") {
      // First clear any existing message
      setFormAlert(null);
      // Then update state to show re-authentication modal
      setReauthState({
        show: true,
        // Failed action to try again after reauth
        callback: callback,
      });
    } else {
      // Display message to user (type is success or error)
      setFormAlert({
        type: type,
        message: message,
      });
      if (type === "success") {
        setTimeout(() => setShowPasswordModal(false), 3000);
      }
    }
  };

  useEffect(() => {
    fetch(priv)
      .then((res) => res.text())
      .then((text) => setPriv_pol_md(text));

    fetch(tos)
      .then((res) => res.text())
      .then((text) => setTos_md(text));
  }, []);

  return (
    <>

      <div className="container">
      <div className="flex w-full flex-col md:flex-row">
    <div className="w-full md:w-1/4 px-4">
      <nav className="flex flex-col md:flex-1 md:flex-col" aria-label="Sidebar">
            <ul role="list" className="-mx-2 space-y-1">
            {navigation.map((item) => (
                <li key={item.name}>
                    <Link
                        to={`/settings/${item.href}`}
                        className={
                        classNames(router.query.section === item.href?"text-green-600 border-r-4 border-solid border-green-600 hover:text-green-600 hover:bg-gray-50":"text-gray-400 hover:text-green-600 hover:bg-gray-50",'group flex gap-x-3 p-2 pl-3 text-md leading-6')
                        }
                    >
                        {item.name}
                    </Link>
                </li>
            ))}
            </ul>
        </nav>
    </div>
        <div className="flex-grow px-4">
        {(router.query.section === "account")&&(
          <>
        <div className="flex w-full">

            <LargeCard titleDescriptions = {[{title: "Account Details", description: "Manage your account settings and preferences easily."}]}>
              {(userStatus==="success" && user)&&(
              <>
              <EntityCard imageUrl = {user.profilePhoto} name = {user.name} role = {user.role} company = {user.company} edit = {true} uid = {auth.user.uid}/>
              <EditInformationCard collection = {"users"} id={auth.user.uid} title = "Information" informationArray = {[
                {name: "Name", value: user.name? user.name: "NA"},
                {name: "Company", value: user.company? user.company: "NA"},
                {name: "Email", value: auth.user.email? auth.user.email: "NA"},
                {name: "Phone Number", value: auth.user.phone? auth.user.phone: "NA"},
                {name: "Role", value: user.role?user.role:"NA"},
                ]}> 
              </EditInformationCard>
              {(showPasswordModal)?<>
                {formAlert && (
                  <div className="mb-4">
                    <FormAlert type={formAlert.type} message={formAlert.message} />
                  </div>
                )}
              <SettingsPassword onStatus = {handleStatus}/>
              </>:<button 
              className="flex flex-row px-4 h-min py-2 mt-2 bg-gray-white text-green-600 border border-solid border-green-600 rounded-md justify-center"
               onClick={() => setShowPasswordModal(true)}>Change Password</button>}

              </>

              )}

            </LargeCard>
        </div>
        </>
        )}
        {(router.query.section === "notifications")&&(
          <>
        <div className="flex w-full">
          <LargeCard titleDescriptions = {[{title: "Notifications", description: "Tailor notifications to suit your workflow and stay informed. Make sure to save your changes before you are done!"}]}>
            {(notificationsStatus==="success"&&notifications && notifications.length===1)&&<NotificationsSection data = {notifications[0]}/>}
          </LargeCard>
        </div>
        </>
        )}
        {(router.query.section === "subscriptions")&&(
          <>
        {(formOpen)&&(
                <div className="z-20 fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center">
                  {/* Popup content */}
                  <div className="bg-white p-8 rounded-lg shadow-lg lg:w-1/2 w-3/4 max-w-4xl relative">
                    {successfullPost===true?(
                    <div className="flex flex-col text-center space-y-4">
                    <CakeIcon className='h-12 text-green-600'/>
                    <h2 className='text-5xl font-semibold'>Form Submitted</h2>
                    <p className='text-lg font-semithin'>Thank you, we will perform the requested changes, or get back with you.</p>
                    <button onClick={()=> {
                      setFormOpen(false) 
                      setSuccessfullPost(false)
                    }} className=" absolute top-0 right-4 text-3xl text-gray-400 rounded-md">
                    &times;
                  </button>
                  </div>
                  ):(
                    <>
                    <h2 className=" text-center text-5xl font-bold pb-6">Submit a Help Form</h2>
                    <p className='text-gray-600 text-center'>Please let us know what we can do for you</p>
                    <button onClick={()=> {
                      setFormOpen(false)
                      setSuccessfullPost(false)
                    }
                     } className=" absolute top-0 right-4 text-3xl text-gray-400 rounded-md">
                      &times;
                    </button>

                    <div className='h-0 my-4 border-t-4 border-dotted border-gray-200'></div>
                    <p className='text-gray-400 text-left m-2'>Please describe changes you would like to make.</p>
                    <div className='mb-4'>
                        <label htmlFor="email" className="sr-only">
                            
                        </label>
                        <input
                            type="text"
                            name="name"
                            id="name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder='"Can you cancel my subscription?"'
                            value={body}
                            onChange={(e) => setBody(e.target.value)}
                        />
                    </div>
                    <button onClick={()=>submitForm()} disabled={buttonDisabled} className="flex flex-row bg-green-600 text-gray-200 py-2 px-4 w-full justify-center rounded-md focus:outline-none focus:border-green-700">Submit Form</button>
                    </>)}
        
                  </div>
                </div>
        )}
        <div className="flex w-full">
        <LargeCard titleDescriptions = {[{title: "Subscriptions", description: "Keep track of your subscription status and manage plans effortlessly. Please note, you can only be using one subscription at a time."}]}>
            
        {/* {auth.user.legacyIsActive&&<div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="ml-3">
              <h3 className="text-lg font-medium text-red-600">Attention</h3>
              <div className="mt-2 text-sm text-red-700">
                <p>Your account has legacy subscriptions that were migrated from the old site. To manage these subscriptions please send us a form by clicking <strong>
                  <button onClick={() => setFormOpen(true)}>here.</button>
                  </strong></p>
              </div>

            </div>
          </div>
        </div>} */}
            <h3 className="text-lg font-semibold">Current Subscriptions</h3>
            <p className="text-gray-400 text-sm mt-1">{auth.user.planIsActive?"Woohoo! You are subscribed to a plan!":"Hmmm, looks like you need a plan?"}</p>
            {(auth.user.planIsActive || auth.user.legacyIsActive)&&(
              <div className="flex flex-row items-center space-x-6">
                <div>
            <p className="text-gray-400 text-sm mt-1">{"Current Subscription(s): "}</p>
            <div className="flex flex-col">
              {auth.user.planIds?.map((each, i ) => 
              <p key = {i} className="text-green-600 text-md mt-1">
              {getPlanName(each)}
              </p>
            )}
            {auth.user.legacyPlans?.map((each, i) => 
              <p key = {i} className="text-green-600 text-md mt-1">
              {getPlanName(each)} Legacy Plan
              </p>
            )}
            </div>
            </div>
            <button onClick={() => setBilling(true)} className="bg-green-700 px-4 py-2 text-white rounded-lg">Manage Subscription</button>
            {(billing)&&<SettingsBilling onStatus={handleStatus}/>}
            </div>
            )}

            <div className='w-full border-gray-200 border-t my-6'/>   
            <h3 className="text-lg font-semibold">Wanna Upgrade?</h3>
            <Link to ="/pricing/plans">
            <button className="bg-green-700 px-4 py-2 text-white rounded-lg w-full">
            Visit our Shop
            </button>
            </Link>
        </LargeCard>
        </div>
        </>
        )}
        {(router.query.section === "tac")&&(
          <>
          <div className="flex w-full">
          <LargeCard titleDescriptions = {[{title: "Terms of Service", description: "THESE TERMS OF SERVICE GOVERN YOUR USE OF OUR WEBSITE: 'HTTPS://WWW.INSURANCEAGENCYTRAINING.ORG'. BY ACCESSING OR USING OUR SITE, YOU AGREE TO BE BOUND BY THESE TERMS."}]}>
            <MarkdownRenderer markdown={tos_md}></MarkdownRenderer>
  
              </LargeCard>
          </div>
          </>
        )}
        {(router.query.section === "privacy")&&(
          <>
        <div className="flex w-full">
        <LargeCard titleDescriptions = {[{title: "Privacy Policy", description: "THIS PRIVACY POLICY EXPLAINS HOW WE COLLECT, USE, AND DISCLOSE PERSONAL INFORMATION OF USERS OF OUR WEBSITE: 'HTTPS://WWW.INSURANCEAGENCYTRAINING.ORG'"}]}>
          <MarkdownRenderer markdown={priv_pol_md}></MarkdownRenderer>

            </LargeCard>
        </div>
        </>
        )}
        {(router.query.section === "about")&&(
          <>
        <div className="flex w-full">
        <LargeCard titleDescriptions = {[{title: "About", description: "Find information about our agency and mission for transparency and trust."}]}>
            <p className="text-gray-500">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus et bibendum orci. Vestibulum blandit consectetur magna non dictum. Pellentesque dolor orci, semper a libero eget, efficitur facilisis turpis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet pretium augue imperdiet pellentesque. Vivamus fringilla diam sed dolor viverra, vel pharetra dolor mattis. Morbi consequat euismod leo, in accumsan tortor aliquam in. Vestibulum vel lorem eu dolor faucibus bibendum. Aenean nec sapien mauris. Duis velit tortor, maximus quis enim nec, congue sodales lectus.
            </p>
            <br/>
            <p className="text-gray-500">
            Sed sollicitudin efficitur ligula a dignissim. Suspendisse molestie, augue a commodo laoreet, sapien sem convallis ex, vel tempor tellus massa in dui. Maecenas eget turpis et neque euismod consequat. Quisque tempus posuere nisl, pellentesque facilisis arcu lobortis non. Donec eu nulla rhoncus, scelerisque arcu a, euismod mauris. Duis ut elementum lorem. In ut augue ante.
            </p>
            <br/>
            <p className="text-gray-500">
            Proin pretium nulla a orci pharetra gravida. Maecenas volutpat a arcu vitae semper. Pellentesque laoreet nibh quis nulla cursus efficitur. Praesent molestie purus ut sapien venenatis, id consequat eros pretium. Proin ac auctor tellus, vel scelerisque sem. Donec purus eros, porta et leo ut, pharetra congue tellus. Vivamus non magna a purus elementum aliquam. Phasellus pretium, est ac vestibulum rhoncus, tellus risus rhoncus lacus, vel consectetur ligula quam ut arcu. Nunc tristique maximus mauris, ut interdum nunc condimentum sed. Nunc eros elit, tincidunt vel aliquet scelerisque, tincidunt eu lorem. Maecenas vitae cursus quam. In tincidunt ligula at massa imperdiet, sit amet eleifend ex bibendum. Phasellus tincidunt nibh sed odio congue tempus.
            </p>
            <br/>
            <p className="text-gray-500">
            Duis imperdiet metus ac molestie efficitur. Nulla consectetur felis sagittis metus fringilla eleifend eu vel metus. Nulla posuere felis quis massa ultrices, sit amet hendrerit risus elementum. Donec rhoncus, risus nec malesuada euismod, dolor erat varius diam, a egestas metus lorem ac leo. Mauris ut lorem cursus, imperdiet mauris eu, tempus est. Nam lobortis sagittis urna, non pulvinar dui consequat eget. Pellentesque ac risus hendrerit, ultrices ante vitae, rhoncus dui. Suspendisse et massa turpis. Praesent vulputate luctus eros. Morbi sit amet mi at ex iaculis ornare. Mauris blandit diam quis finibus venenatis. Aenean feugiat erat ac molestie vulputate. Nunc vel congue nunc.
            </p>
            <br/>
            <p className="text-gray-500">
            Donec condimentum mauris sit amet pretium finibus. Pellentesque tempus dignissim mauris, a feugiat lorem mollis vel. Maecenas aliquet neque ante, in aliquet felis feugiat et. Duis faucibus mi eu sem vulputate, ut sollicitudin magna dictum. Praesent tristique metus in convallis vulputate. Morbi quam ligula, egestas ac enim at, fermentum convallis nulla. Vivamus scelerisque placerat bibendum. Integer elementum urna eros, vel dictum nulla consectetur et. Sed lacinia elit ultricies dapibus auctor. Sed eleifend, enim a euismod aliquet, velit arcu ornare turpis, porttitor tincidunt ex felis in nibh. Aliquam non egestas magna. Proin finibus, nisi at facilisis hendrerit, libero ipsum mollis eros, id dictum justo est et nunc. Pellentesque mattis ipsum a euismod scelerisque.
            </p>
            </LargeCard>
        </div>
        </>
        )}
        {(router.query.section === "billing")&&(
          <>
          <SettingsBilling onStatus = {handleStatus}/>
        </>
        )}
        </div>
    </div>

      </div>
      <SideNav current = "settings"></SideNav>

    </>

  );
}

export default CustomSettingsSection;
