import React from 'react';
import { UserCircleIcon } from "@heroicons/react/24/solid";
const planDict = {
    life: "Million-Dollar Membership",
    sbm: "Stairs and Buckets",
    sby: "Stairs and Buckets",
    proc: "Processes Package",
    tp: "Trainings Package",
}

const VideoTestimonialCard = (props) => {
  return (
    <div
    className="flex-1 border rounded-md border-green-700 relative min-w-[300px] max-w-full"
  >
    <div className="absolute top-0 right-0 text-8xl mt-1 mr-2 text-green-700 opacity-75 font-serif">
      “
    </div>
    <span className=" absolute top-0 left-0  mt-4 ml-4 inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
          {planDict[props.plan]}
      </span>
    <div className="px-4 pt-14 pb-6 sm:px-6 sm:pb-6 relative">
      <blockquote>
      <div className="w-full flex flex-row justify-center">
        <iframe
          src={"https://player.vimeo.com/video/" + props.embed + "?&title=0&byline=0&portrait=0&badge=0&dnt=1"}
          width="fit"
          height="500"
          style={{ border: 'none' }}
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title="Vimeo Video"
          className="rounded-lg m-0 border-none overflow-hidden"
        />      
      </div>
        <p className="leading-7 mb-2 font-bold">{props.title}</p>

        <footer className="flex items-center space-x-4">
          <UserCircleIcon className="h-16" />
          <div>
            <span className="font-semibold text-green-600">
              {props.display_name}
            </span>
          </div>
        </footer>
      </blockquote>
    </div>
  </div>
  );
};

export default VideoTestimonialCard;
