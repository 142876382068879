import React from 'react';
import { ClockIcon, EyeIcon, ArrowRightOnRectangleIcon, ChartBarSquareIcon } from '@heroicons/react/24/solid';
import image from "../../assets/stairsandbuckets.png"
import LinkCard from './LinkCard';

const AnalyticsCard = ( props ) => {
  return (
    <div className="flex flex-col md:flex-row border border-green-700 rounded-lg overflow-hidden items-center">
      {/* Image Section */}
      <div className="p-4 w-full">
        <img src={image} alt="Descriptive Alt Text" className="object-fit rounded-3xl"/>
      </div>
      
      {/* Stats Section */}
      <div className="px-4 flex flex-col justify-center w-full ">
      <LinkCard title = "Analytics">
        <ul>
            <li className="flex justify-between items-center">
                <div className='flex flex-row items-center'>
                <ArrowRightOnRectangleIcon className='m-2 p-3 h-12 text-green-600 bg-gray-100 border-green-600 border rounded-lg'/>
                <div>
                <span className="font-semibold">Shares:</span>
                <p className='text-gray-400 text-xs'>Total Video Shares</p>
                </div>
                </div>
                <div className='flex flex-col items-end'>
                  {(props.showChange)?<>
                    <span className='text-green-600'>{props.sharePerc?props.sharePerc>=0?`+${props.sharePerc}%`:`-${props.sharePerc}%`:'--%'}</span>
              <p className='text-gray-400 text-xs'>{`${props.shares?props.shares:0} Share(s)`}</p>
                  </>:<>
                  <span className='text-green-600'>{(props.shares?props.shares:0) + " Share(s)"}</span>
                  </>}

                </div>
            </li>
            <li className="flex justify-between items-center">
            <div className='flex flex-row items-center'>
                <EyeIcon className='m-2 p-3 h-12 text-green-600 bg-gray-100 border-green-600 border rounded-lg'/>
                <div>
                <span className="font-semibold">Views:</span>
                <p className='text-gray-400 text-xs'>Total Number of Views</p>
                </div>
                </div>
                <div className='flex flex-col items-end'>
                {(props.showChange)?<>
                  <span className='text-green-600'>{props.viewPerc?props.viewPerc>=0?`+${props.viewPerc}%`:`-${props.viewPerc}%`:'--%'}</span>
                  <p className='text-gray-400 text-xs'>{`${props.views?props.views:0} View(s)`}</p>
                  </>:<>
                  <span className='text-green-600'>{(props.views?props.views:0) + " View(s)"}</span>
                  </>}
                
                </div>            </li>
            <li className="flex justify-between items-center">
            <div className='flex flex-row items-center'>
                <ClockIcon className='m-2 p-3 h-12 text-green-600 bg-gray-100 border-green-600 border rounded-lg'/>
                <div>
                <span className="font-semibold">Watch Hours:</span>
                <p className='text-gray-400 text-xs'>Total Hours Watched</p>
                </div>
                </div>
                <div className='flex flex-col items-end '>
                {(props.showChange)?<>
                  <span className='text-green-600'>{props.hourPerc?props.hourPerc>=0?`+${props.hourPerc}%`:`-${props.hourPerc}%`:'--%'}</span>
              <p className='text-gray-400 text-xs'>{`${props.hours?props.hours:0} Hour(s)`}</p>
                  </>:<>
                  <span className='text-green-600'>{(props.hours?props.hours:0) + " Hour(s)"}</span>
                  </>}
                
                </div>            </li>
            <li className="flex justify-between items-center">
            <div className='flex flex-row items-center'>
                <ChartBarSquareIcon className='m-2 p-3 h-12 text-green-600 bg-gray-100 border-green-600 border rounded-lg'/>
                <div>
                <span className="font-semibold">Confirmed Sales:</span>
                <p className='text-gray-400 text-xs'>Total Sales from Video</p>
                </div>
                </div>
                <div className='flex flex-col items-end'>
                {(props.showChange)?<>
                  <span className='text-green-600'>{props.salePerc?props.salePerc>=0?`+${props.salePerc}%`:`-${props.salePerc}%`:'--%'}</span>
              <p className='text-gray-400 text-xs'>{`${props.sales?props.sales:0} Sale(s)`}</p>
                  </>:<>
                  <span className='text-green-600'>{(props.sales?props.sales:0) + " Sale(s)"}</span>
                  </>}

                </div>            </li>
        </ul>
        </LinkCard>
      </div>

    </div>
  );
};

export default AnalyticsCard;
