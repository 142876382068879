import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { ArrowLongLeftIcon, ArrowLongRightIcon, ChevronDownIcon,  } from '@heroicons/react/24/solid';
import TaskBuilderButton from './TaskBuilderButton';
import { TrashIcon } from '@heroicons/react/24/outline';
import { deleteTask } from '../../util/db';

const CalendarComponent = (props) => {
    const rows = 6;
    const cols = 7;
    const totalCells = rows * cols;
    const [option, setOption] = useState("Monthly")
    const [isOpen, setIsOpen] = useState(false)
    const scrollContainerRef = useRef(null);


    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const monthsOfTheYear = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October","November", "December"]
    const [dateTime, setDateTime] = useState(new Date());
    const [showCodes, setShowCodes] = useState(true)
    const [showTasks, setShowTasks] = useState(true)
    const [workingTask,setWorkingTask] = useState()

    const [infoPopup, setInfoPopup] = useState(false)
    const [currentTask, setCurrentTask] = useState({})

    const [selectedDay, setSelectedDay] = useState(dateTime.getDate())
    const [selectedMonth, setSelectedMonth] = useState(dateTime.getMonth())
    const [selectedYear, setSelectedYear] = useState(dateTime.getFullYear())
    const [weekOffset, setWeekOffset] = useState(0)
    const [deleteDisabled, setDeleteDisabled] = useState(false)

    const monthOfTheYear = monthsOfTheYear[selectedMonth]
    const firstOfMonth = getDayOfWeek(1, selectedMonth, selectedYear)
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
      };

  useEffect(() => {
    const timer = setInterval(() => {
      setDateTime(new Date());
    }, 60000);

    return () => clearInterval(timer); // Cleanup on component unmount
  }, []);

  function showInfoPopup(task) {
    setCurrentTask(task)
    setInfoPopup(!infoPopup)
  }

  function getTimeFromSeconds(seconds) {
    var date = new Date(seconds*1000)
    return `${date.getHours()}:${date.getMinutes()<10?`0${date.getMinutes()}`:date.getMinutes()}`
  }
  function getDayOfWeek(day, month, year) {
    const date = new Date(year, month, day); // Month is 0-based in JavaScript Date
    const dayOfWeek = date.getDay();  
    return dayOfWeek;
  }

  function getDate(day, month, year) {
    const date = new Date(year, month, day); // Month is 0-based in JavaScript Date
    const dayOfWeek = date.getDate();  
    return dayOfWeek
  }
  function getMonth(day, month, year) {
    const date = new Date(year, month, day); // Month is 0-based in JavaScript Date
    const dayOfWeek = date.getMonth();  
    return dayOfWeek
  }
  function getYear(day, month, year) {
    const date = new Date(year, month, day); // Month is 0-based in JavaScript Date
    const dayOfWeek = date.getFullYear();  
    return dayOfWeek
  }

  function iterateForward() {
    var month = getMonth(selectedDay+1, selectedMonth, selectedYear)
    var year = getYear(selectedDay+1, selectedMonth, selectedYear)

     if (month !== selectedMonth) {
      setSelectedMonth(month)
      setSelectedYear(year)
      setSelectedDay(1)
     } else {
      setSelectedDay(selectedDay+1)
     }
     setInfoPopup(false)
     setCurrentTask({})
  }

  function iterateBackward() {
    var month = getMonth(selectedDay-1, selectedMonth, selectedYear)
    var year = getYear(selectedDay-1, selectedMonth, selectedYear)
    var date = getDate(selectedDay-1, selectedMonth, selectedYear)
     if (month !== selectedMonth) {
      setSelectedMonth(month)
      setSelectedYear(year)
      setSelectedDay(date)
     } else {
      setSelectedDay(selectedDay-1)
     }
     setInfoPopup(false)
     setCurrentTask({})
  }

  function moveForward() {
    if (option === "Monthly") {
    if (selectedMonth ===  11) {
        setSelectedMonth(0)
        setSelectedYear(selectedYear+1)
    } else {
        setSelectedMonth(selectedMonth+1)
    }
    setSelectedDay(1)
    setInfoPopup(false)
    setCurrentTask({})
    } 
    if (option === "Weekly") {
        if (getMonth(6+weekOffset - (firstOfMonth -1), selectedMonth, selectedYear) !== selectedMonth) {
          if (selectedMonth ===11) {
            setSelectedYear(selectedYear+1)
            setSelectedMonth(0)
          } else{
            setSelectedMonth(getMonth(6+weekOffset - (firstOfMonth -1), selectedMonth, selectedYear))
          }
          setWeekOffset(0)
        } else {
          setWeekOffset(weekOffset+7)
        }
    }
  }

  function moveBackward() {
    if (option === "Monthly") {
    if (selectedMonth ===  0) {
        setSelectedMonth(11)
        setSelectedYear(selectedYear-1)
    } else {
        setSelectedMonth(selectedMonth-1)
    }
    setInfoPopup(false)
    setCurrentTask({})
    setSelectedDay(1)
    }
    if (option === "Weekly") {
      if (getMonth(weekOffset - (firstOfMonth -1), selectedMonth, selectedYear) !== selectedMonth) {
        if (selectedMonth === 0) {
          setSelectedMonth(11)
          setSelectedYear(selectedYear-1)
        } else{
          setSelectedMonth(getMonth(weekOffset - (firstOfMonth -1), selectedMonth, selectedYear))
        }
        setWeekOffset(Math.floor(getDate(weekOffset - (firstOfMonth -1), selectedMonth, selectedYear)/7)*7)
      } else {
        setWeekOffset(weekOffset-7)
      }
    }
  }

  function indexCodes(codes, cols, rows, ) {
    var array = new Array(cols*(rows-1)).fill(0);
    codes.forEach(code => {
        var codeDate = new Date(code.createdAt.seconds*1000)
        if (codeDate.getFullYear() === selectedYear && codeDate.getMonth() === selectedMonth) {
            array[codeDate.getDate()+firstOfMonth-1] = array[codeDate.getDate() + firstOfMonth-1] +1
        }
    })
    return array
  }

  async function deleteFunction(id) {
    setDeleteDisabled(true)
    await deleteTask(id)
    setDeleteDisabled(false)
  }

  function indexTasks(tasks, cols, rows) {
    // Initialize an array with distinct empty arrays
    var array = new Array(cols * (rows - 1)).fill(null).map(() => []);

    tasks.forEach(task => {
        var codeDate = new Date(task.time.seconds * 1000);
        if (codeDate.getFullYear() === selectedYear && codeDate.getMonth() === selectedMonth) {
            array[codeDate.getDate() + firstOfMonth - 1].push(task);
        }
    });

    return array;
}
  const handleValueChange = (newValue) => {
    setWorkingTask(newValue);
    setInfoPopup(false)
    setCurrentTask({})
  };
  var codesArray = props.codes?indexCodes(props.codes, cols, rows):[]
  var tasksArray = props.tasks?indexTasks(props.tasks, cols, rows):[]

  useEffect(() => {
    // Set the scroll position to halfway
    if (scrollContainerRef.current) {
      const scrollHeight = scrollContainerRef.current.scrollHeight;
      const clientHeight = scrollContainerRef.current.clientHeight;
      scrollContainerRef.current.scrollTo({
        top: dateTime.getDate()===selectedDay?(scrollHeight - clientHeight)* ((dateTime.getHours()-2)/16): 0.5*(scrollHeight-clientHeight),
        behavior: 'smooth', // Optional: Adds a smooth scrolling animation
      });
    }
  }, [showTasks, selectedDay]);

  return (
    <>
    <div className="flex flex-row justify-between items-center pb-2 px-6">
    <div className='flex flex-row'>
    <nav className="flex items-center justify-between">
        <div className="flex-shrink-0 flex items-center">
        <button onClick={() =>moveBackward()} className="inline-flex items-center px-2 text-sm font-medium text-gray-500 hover:text-gray-700">
            <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
            Previous
        </button>
        </div>
        <span className="mx-4 flex items-center">{
        (option === "Monthly")?`${monthOfTheYear}, ${selectedYear}`:`
        ${monthsOfTheYear[getMonth(weekOffset - (firstOfMonth -1), selectedMonth, selectedYear)]} ${getDate(weekOffset - (firstOfMonth -1), selectedMonth, selectedYear)}
        - ${monthsOfTheYear[getMonth(6+weekOffset - (firstOfMonth -1), selectedMonth, selectedYear)]} ${getDate(6+weekOffset - (firstOfMonth -1), selectedMonth, selectedYear)}
        `
        }</span>
        <div className="flex-shrink-0 flex items-center">
        <button onClick={() =>moveForward()} className="inline-flex items-center px-2 text-sm font-medium text-gray-500 hover:text-gray-700">
            Next
            <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
        </button>
        </div>
    </nav>
    <div className="relative inline-block text-left">
      {/* Button to toggle the dropdown */}
      <button onClick={toggleDropdown} className="flex flex-row bg-white text-green-500 py-2 px-4 rounded-md border-2 border-green-200 focus:outline-none focus:border-green-700">
        {option}
        <ChevronDownIcon className='h-6 ml-0.5'/>
      </button>

      {/* Dropdown Menu */}
      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <button className="text-green-500 block px-4 py-2 text-sm w-full text-left hover:bg-green-100" role="menuitem" onClick={() => {toggleDropdown()
                setOption("Weekly")
                setSelectedMonth(dateTime.getMonth())
                setWeekOffset(Math.floor(dateTime.getDate()/7)*7)
                }}>Weekly</button>
            <button className="text-green-500 block px-4 py-2 text-sm w-full text-left hover:bg-green-100" role="menuitem" onClick={() => {toggleDropdown()
                setOption("Monthly")
                setWeekOffset(0)
                setSelectedMonth(dateTime.getMonth())
            }}>Monthly</button>
          </div>
        </div>
      )}
    </div>
    </div>
    <div className='flex flex-row space-x-12'>
    <div className='flex flex-row'>
    <div className="flex h-6">
            <input
              id="show-codes"
              name="show-codes"
              type="checkbox"
              checked={showCodes}
              onChange={() => setShowCodes(!showCodes)}
              className=" h-6 w-6 rounded text-green-600"
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label htmlFor="show-codes" className="font-medium text-gray-900">
              Show Sent Codes
            </label>
          </div>
    </div>
    <div className='flex flex-row'>
    <div className="flex h-6">
            <input
              id="show-tasks"
              name="show-tasks"
              type="checkbox"
              checked={showTasks}
              onChange={() => {
                if (selectedMonth === dateTime.getMonth()) {
                  setSelectedDay(dateTime.getDate())
                }
                setShowTasks(!showTasks)
              }
              }
              className=" h-6 w-6 rounded text-green-600"
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label htmlFor="show-tasks" className="font-medium text-gray-900">
              Show Tasks
            </label>
          </div>
    </div>
    </div>
    </div>
      {(option === "Monthly")&&(
        <div className='flex flex-row'>
          <div className="grid grid-cols-7 rounded-lg border p-2 divide-y divide-gray-200 divide-x w-full">
          {Array.from({ length: totalCells }, (_, i) => (
              <div key = {i} className={`${ i > 7?"h-24":""}`}>
              <div
              key={i}
              className={`flex justify-center text-center 
                  ${i > 6 && ((i - (6 + firstOfMonth)) > 0) ? (i > 25 && getDate(i - (6 + firstOfMonth), selectedMonth, selectedYear) < 10) ? "font-thin" : "font-bold" : "font-thin"} 
                  ${i === 0 ? "border-t border-l":""} 
                  ${(i>6 &&dateTime.getDate() === getDate(i+weekOffset - (6 + firstOfMonth), selectedMonth, selectedYear)&&dateTime.getMonth()===getMonth(i+weekOffset - (6 + firstOfMonth), selectedMonth, selectedYear)&&dateTime.getFullYear()===selectedYear) ? " bg-gradient-to-t from-white via-white to-green-700 ":""} 
                  ${i < 7 ? "items-center font-semibold text-lg" : "text-md pt-4"}`}
              >
              {i < 7 ? days[i] : getDate(i - (6 + firstOfMonth), selectedMonth, selectedYear)}
              </div>
                <div className="flex flex-row justify-center">
                  <div className="flex flex-col items-center space-y-1">
                    {(props.codes && showCodes && codesArray[i-7] > 0)&&(
                      <span className="inline-flex items-center w-min rounded-full bg-green-50 px-1 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 whitespace-nowrap">
                      {`${codesArray[i-7]} ${codesArray[i-7] === 1 ? "Code" : "Codes"} Sent`}
                    </span>
                    )}
                    {(props.tasks && showTasks && tasksArray[i-7]?.length > 0)&&(
                    <span className="inline-flex items-center w-min  rounded-full bg-yellow-50 px-1 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20 whitespace-nowrap">
                    {`${tasksArray[i-7]?.length} ${tasksArray[i-7]?.length === 1 ? "Task" : "Tasks"} Scheduled`}
                  </span>
                    )}
                  </div>
                </div>
            </div>
          ))}
        </div>
        {(showTasks)&&<div className='flex flex-col w-1/4'>
          {infoPopup&&
          <div
           className=' absolute -translate-x-full z-40 border bg-yellow-50 text-lg rounded-lg border-yellow-600 text-yellow-600 w-1/4 overflow-scroll p-1 text-center'>
            {currentTask.title}
            <p className='text-md text-gray-400'>{getTimeFromSeconds(currentTask.time.seconds)+" - " + getTimeFromSeconds(currentTask.time.seconds+(60*currentTask.duration))}</p>
            <p className='text-sm text-gray-700'>{currentTask.description}</p>
          </div>}
        <TaskBuilderButton uid = {props.uid} onValueChange = {handleValueChange} selectedDay={selectedDay} selectedMonth={selectedMonth} selectedYear={selectedYear}/>
        <div className=' mx-2 rounded-lg flex flex-col w-full text-center border border-green-700 border'>
          <div className='flex flex-row justify-around bg-green-50 rounded-t-lg border-b border-green-700'>
            <button onClick={() => iterateBackward()} className="inline-flex items-center px-2 text-sm font-medium text-gray-500 hover:text-gray-700">
              <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-green-700" aria-hidden="true" />
            </button>
            <p className="font-normal text-green-700 ">{monthsOfTheYear[selectedMonth] + " " +  selectedDay + ", " + selectedYear}</p>
            <button onClick={() => iterateForward()} className="inline-flex items-center px-2 text-sm font-medium text-gray-500 hover:text-gray-700">
              <ArrowLongRightIcon className="mr-3 h-5 w-5 text-green-700" aria-hidden="true" />
            </button>
          </div>
        <div ref={scrollContainerRef} className="relative h-[464px] text-center overflow-scroll">
        {Array.from({ length: 24 }).map((_, index) => (
          <div
            key={index}
            className="absolute w-full border-t border-dashed border-gray-300 text-gray-400 text-left text-xs ml-1"
            style={{ top: `${(index) * (504 / 8)}px`, height: `63px` }}
          >
          {`${index}:00`}
          </div>
        ))}
        <div className='flex flex-col items-end hover:cursor-pointer'>
        {tasksArray[selectedDay+firstOfMonth-1]?.map((task, i) =>
          <div 
      key={i}
      style={{
        top: `${(new Date(task.time.seconds * 1000).getHours() + new Date(task.time.seconds * 1000).getMinutes() / 60) * (504 / 8)}px`,
        height: `${(task.duration / 60) * 63}px`
      }}
      className='absolute flex flex-row w-full hover:w-5/6 transition-all duration-300 ease-in-out items-center group'>
      <div className='relative flex items-center w-full'>
        <button disabled={deleteDisabled} onClick={() => deleteFunction(task.id)} className='absolute left-0 rounded-full border-red-500 border text-red-500 hover:bg-red-100 h-min p-1 opacity-0 group-hover:opacity-100 transition-opacity duration-100 ease-in-out'>
          <TrashIcon className='h-6'/>
        </button>
        <button onClick={() => showInfoPopup(task)}
          className='ml-10 border-y border-l text-sm rounded-l-lg border-yellow-600 text-yellow-600 bg-yellow-50 w-full p-1 overflow-scroll h-full pl-3'>
          {task.title}
          <p className='text-sm text-gray-400'>{getTimeFromSeconds(task.time.seconds) + " - " + getTimeFromSeconds(task.time.seconds + (60 * task.duration))}</p>
        </button>
      </div>
    </div>
        )}
        {workingTask&&
          <div
           style={{
                    top: `${(new Date(workingTask.time.seconds*1000).getHours()+new Date(workingTask.time.seconds*1000).getMinutes()/60)*(504/8)}px`,
                    height: `${(workingTask.duration/60) *63 }px`,
                    backgroundImage: 'repeating-linear-gradient(45deg, #e9d5ff, #e9d5ff 10px, white 10px, white 20px)'
                  }}
           className=' absolute border-y border-l text-sm rounded-l-lg border-purple-700 text-purple-700 w-5/6 overflow-scroll p-1'>
            {workingTask.title}
            <p className='text-sm text-gray-700'>{getTimeFromSeconds(workingTask.time.seconds)+" - " + getTimeFromSeconds(workingTask.time.seconds+(60*workingTask.duration))}</p>
          </div>}
        </div>
        {(selectedDay===dateTime.getDate()&&dateTime.getMonth() === selectedMonth && dateTime.getFullYear()===selectedYear)&&<div className='absolute w-full border-t border border-green-600' style={{top: `${(dateTime.getHours()+dateTime.getMinutes()/60)*(504/8)}px`}}></div>}
      </div>
      </div>
      </div>

      }
        
      </div>

    )}

    {(option === "Weekly")&&(
        <div className="grid grid-cols-7 rounded-lg border p-2 divide-y divide-gray-200 divide-x">
            {Array.from({ length: cols*2 }, (_, i) => (
        <div key = {i} className={`${ i >= 7?"h-[470px]":""}`}>
        <div
        className={`flex justify-center text-center 
            ${i === 0 ? "border-t border-l":""} 
            ${i>6 &&(dateTime.getDate() === getDate(i+weekOffset - ( firstOfMonth+6), selectedMonth, selectedYear)&&dateTime.getMonth()===getMonth(i+weekOffset - (firstOfMonth -1), selectedMonth, selectedYear)&&dateTime.getFullYear()===selectedYear) ? " bg-gradient-to-t from-white via-white to-green-700 h-8 ":" h-8"} 
            ${i < 7 && getMonth(i + weekOffset - (firstOfMonth-1), selectedMonth, selectedYear) !== selectedMonth ? "items-center font-thin text-lg" : "font-normal items-center text-lg"}`}
        >
        {i < 7 ? `${days[i]} ${getMonth(i+weekOffset - (firstOfMonth -1), selectedMonth, selectedYear)+1}/${getDate(i + weekOffset - (firstOfMonth -1), selectedMonth, selectedYear)}` : " "}
        </div>
        { i> 7 && props.codes &&showCodes&& codesArray[i+weekOffset-7] > 0 &&(
        <> 
        <div className="flex flex-row justify-center py-2">
        <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
            {`${codesArray[i+weekOffset-7]} ${codesArray[i+weekOffset-7]===1?"Code":"Codes"} Sent`}
        </span>
        </div>
        </>
        )}
      </div>
    ))}
        </div>
    )}

    </>
  );
};

export default CalendarComponent;
